import React from "react"
const ESDF = () => {
  return (
    <section>
      <div className="container">
        <div className={"row"}>
          <div
            className={
              "col-12 d-flex justify-content-center align-items-center"
            }
            style={{ height: "100vh" }}
          >
            <div>
              <div style={{ textAlign: "center" }} className="pb-70">
                <img src="/images/esdf_logo.png" style={{ maxWidth: 200 }} />
              </div>
              <h2>Education and Skill Development Foundation</h2>
              <p>
                Education and Skill Development Foundation (ESDF) is a group of
                individuals who actively work for education, skill development
                and academic development of students in Dakshin Kamrup region of
                the State Assam, India.
              </p>

              <p>
                Visit <a href="https://esdf.in">www.esdf.in</a> or{" "}
                <a href="https://esdf.in">Click Here</a> to learn more.
              </p>
              <div className="gap-20" />
              <div>
                <a href="/" className="default-btn">
                  Go Back to Darwin Academy, Mirza Homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ESDF
